import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class Message extends React.Component {
  // TODO: Add support for displaying multiple images.
  render() {
    const messageJson = this.props.messageJson;
    var imageRow = '';
    var textRow = '';
    if (messageJson.MultimediaContent != null && messageJson.MultimediaContent.ImageUrls != null && messageJson.MultimediaContent.ImageUrls.urls != null) {
      const widthSm = Math.floor(8 / messageJson.MultimediaContent.ImageUrls.urls.length);
      const widthMd = Math.floor(10 / messageJson.MultimediaContent.ImageUrls.urls.length);
      const width = messageJson.MultimediaContent.ImageUrls.urls.length <= 1 ? '45%' : '100%'
      const imageCols = [];
      for (const imageUrl of messageJson.MultimediaContent.ImageUrls.urls) {
        imageCols.push(
          <Col sm={widthSm} md={widthMd}><a href={imageUrl} target='_blank' rel='noreferrer'>
              <img
                  style={{'object-fit': 'cover', width: width, height: '250px', cursor: 'pointer'}}
                  src={imageUrl}
                  onLoad={this.props.onImgLoaded(messageJson.id)} /></a>
          </Col>
        );
      }
      imageRow = (
        <Row style={{margin: '10px'}}>
          <Col sm={4} md={2}>{messageJson.sender_name}:</Col>
          {imageCols}
        </Row>
      );
    }
    if (messageJson.text_content != null && messageJson.text_content !== '') {
      textRow = (
        <Row style={{margin: '10px'}}>
          <Col sm={4} md={2}>{messageJson.sender_name}:</Col>
          <Col sm={8} md={10}>{messageJson.text_content}</Col>
        </Row>
      );
    }
    return (
      <div>
      {imageRow}
      {textRow}
      </div>
    );
  }
}

export default Message;
