import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import * as constants from './../../Constants.js';

class NameInputModal extends React.Component {
  constructor(props) {
    super(props);

    this.username = '';

    this.state = {
      showUsernameTaken: false
    }

    this.onOK = this.onOK.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.detectEnterSubmit = this.detectEnterSubmit.bind(this);
  }

  onOK() {
    if (this.username !== '') {
      this.registerUser(this.props.eventId, this.username);
    }
  }

  onUsernameChange(event) {
    this.username = event.target.value;
  }

  detectEnterSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onOK();
    }
  }

  registerUser(eventId, username) {
    fetch(constants.API_ADDRESS + '/api/event/' + eventId + '/attendee', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({
        'username': username
      })
    }).then(response => response.json())
    .then(response => {
      if (response.status == 0 || response.status == null) {
        this.props.onUsernameChosen(response.attendee.username, response.attendee.auth_token);
      } else {
        // TODO: Handle other error codes cleanly.
        this.setState({showUsernameTaken: true})
      }
    });
  }

  render() {
    return (
      <Modal show={this.props.show} backdrop="static">
        <Modal.Header>
          <Modal.Title>Enter your username for this chat</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p style={{visibility: this.state.showUsernameTaken ? 'visible' : 'hidden'}}>Sorry, that username is taken.</p>
          <input
              type="text"
              className="form-control form-control-md"
              placeholder=""
              onChange={this.onUsernameChange}
              onKeyDown={this.detectEnterSubmit} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={this.onOK}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NameInputModal;
