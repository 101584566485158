import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Cookies from 'js-cookie';
import io from 'socket.io-client';

import * as constants from './../../Constants.js';

import NameInputModal from './NameInputModal.js';
import Message from './Message.js';

function createObjectURL(object) {
  return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}

function removeAtIndex(arr, index) {
  const newArr = [];
  for (var i = 0; i < arr.length; i++) {
    if (i != index) {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}

class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: '',
      eventData: {},
      messages: [],
      uploadImageSrcs: [],
      isCurrentlySubmitting: false,
      showNameInputModal: true
    };
    this.newMessageValue = '';
    this.filesToUpload = [];
    this.messagesDisplay = React.createRef()

    this.onUsernameChosen = this.onUsernameChosen.bind(this);
    this.onNewMessageChange = this.onNewMessageChange.bind(this);
    this.fileInputChange = this.fileInputChange.bind(this);
    this.detectEnterSubmit = this.detectEnterSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const eventId = this.props.match.params.eventId;
    this.setState({eventId: eventId});
    this.loadEventData(eventId, (eventData) => {
      if (eventData.event != null) {
        this.setState({eventData: eventData.event});
      }
    });

    this.loadMessages(eventId, (messages) => {
      if (messages.messages != null) {
        const processedMessages = messages.messages;
        processedMessages.sort((e1, e2) => {return e1.timestamp_ms > e2.timestamp_ms});
        this.setState({messages: processedMessages});
      }
    });

    const username = Cookies.get('/event/' + eventId + '/username');
    const authToken = Cookies.get('/event/' + eventId + '/authToken');
    if (username == null || username === '') {
      console.log('show modal');
      this.setState({showNameInputModal: true});
    } else {
      // TODO: Check auth token as well before trying to connect socket. Although currently there is no security issue, it may cause weird UI where user cannot post and doesn't know why.
      this.onUsernameChosen(username, authToken);
    }

    this.scrollToBottom();
  }

  componentDidUpdate() {
    document.getElementById("fileInput").onchange = this.fileInputChange;
    // TODO: Only scroll to bottom if user is already at the bottom.
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this.clientSocket.disconnect();
  }

  loadEventData(eventId, onEventDataFn) {
    fetch(constants.API_ADDRESS + '/api/event/' + eventId, {
      method: 'GET',
      crossDomain: true,
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      onEventDataFn(response);
    });
  }

  loadMessages(eventId, onRetrievedMessagesFn) {
    fetch(constants.API_ADDRESS + '/api/channel/' + eventId + '/latest-messages', {
      method: 'GET',
      crossDomain: true,
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      onRetrievedMessagesFn(response);
    });
  }

  initSocket(url, handshake) {
    const clientSocket = io(url, {
      withCredentials: false,
      forceNew: true,
      query: handshake,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10
    });
    clientSocket.on('connect', () => {
      console.log('connected!')
    });
    clientSocket.on('on_message', (msg) => {
      const messages = this.state.messages;
      messages.push(JSON.parse(msg));
      this.setState({messages: messages});
    });
    clientSocket.on('disconnect', (reason) => {
      console.log('goodbye');
    });

    return clientSocket;
  }

  onUsernameChosen(username, authToken) {
    this.clientSocket = this.initSocket(constants.API_ADDRESS, 'channel=' + this.props.match.params.eventId + '&username=' + username + '&auth_token=' + authToken);
    Cookies.set('/event/' + this.props.match.params.eventId + '/username', username);
    Cookies.set('/event/' + this.props.match.params.eventId + '/authToken', authToken);
    console.log('hiding modal');
    this.setState({showNameInputModal: false});
  }

  onNewMessageChange(event) {
    this.newMessageValue = event.target.value;
    if (this.state.uploadImageSrcs.length < 4 && this.newMessageValue.startsWith('http') && this.newMessageValue.endsWith('.jpg')) {
      const uploadImageSrcs = this.state.uploadImageSrcs;
      uploadImageSrcs.push({type: 'url_upload', src: this.newMessageValue});
      this.setState({uploadImageSrcs: uploadImageSrcs});
    }
  }

  getFileInput() {
    var files = document.getElementById("fileInput").files;
    if (files.length === 0) {
      return null;
    }
    var file = files[0];
    if(!file){
      return null;
    }
    return file;
  }

  fileInputChange() {
    var file = this.getFileInput();
    var src = createObjectURL(file);
    const uploadImageSrcs = this.state.uploadImageSrcs;
    uploadImageSrcs.push({type: 'file_upload', src: src});
    this.setState({uploadImageSrcs: uploadImageSrcs});
    this.filesToUpload[src] = file;
    document.getElementById("fileInput").value = '';
  }

  detectEnterSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  onSubmit() {
    this.setState({isCurrentlySubmitting: true});
    const uploadImageSrcs = this.state.uploadImageSrcs;
    if (uploadImageSrcs.length > 0) {
      const uploadPromises = {};
      const uploadPromisesList = [];
      for (const src in this.filesToUpload) {
        uploadPromises[src] = this.uploadImageToAws(src, this.filesToUpload[src]);
        uploadPromisesList.push(uploadPromises[src]);
      }
      const newMessageValue = this.newMessageValue;
      Promise.all(uploadPromisesList).then((rawUploadedSrcToUrls) => {
        const uploadedSrcToUrls = {};
        for (const rawUploadedSrcToUrl of rawUploadedSrcToUrls) {
          uploadedSrcToUrls[rawUploadedSrcToUrl.src] = rawUploadedSrcToUrl.url;
        }
        const uploadedUrls = [];
        for (const uploadedImageSrc of uploadImageSrcs) {
          if (uploadedImageSrc.src in uploadedSrcToUrls) {
            uploadedUrls.push(uploadedSrcToUrls[uploadedImageSrc.src]);
          } else {
            uploadedUrls.push(uploadedImageSrc.src);
          }
        }
        this.clientSocket.emit('new_message', JSON.stringify({
          text_content: newMessageValue,
          image_urls: {urls: uploadedUrls}
        }));
      }, (err) => {console.log(err)});
    } else if (this.newMessageValue !== '') {
      this.clientSocket.emit('new_message', JSON.stringify({text_content: this.newMessageValue}));
    }

    // Reset.
    this.newMessageValue = '';
    this.refs.messageField.value = '';
    this.filesToUpload = {};
    this.setState({uploadImageSrcs: [], isCurrentlySubmitting: false});
  }

  uploadImageToAws(src, file) {
    return new Promise((resolve, reject) => {
      fetch(constants.API_ADDRESS + '/api/s3/signed_url?fileType=' + file.type, {
        method: 'GET',
          crossDomain: true,
        }).then(response => response.json())
        .then(response => {
          console.log('got signed request', response);
          if (response.status === 'error') {
            return;
          }
          const formData = new FormData();
          var uploadedUrl = response.signed_s3_url;
          formData.append('file', file);
          fetch(uploadedUrl, {
            method: 'PUT',
            crossDomain: true,
            body: file
          }).then(response => response.text()).then(response => {
            console.log('submitted to S3', response, uploadedUrl);
            resolve({src: src, url: uploadedUrl.split('?')[0]});
          }).catch(err => {
            reject(err);
          });
      });
    });
  }

  removeFromImageUploads(index) {
    this.filesToUpload = removeAtIndex(this.filesToUpload, index);
    const uploadImageSrcs = this.state.uploadImageSrcs;
    this.setState({uploadImageSrcs: removeAtIndex(this.state.uploadImageSrcs, index)});
  }

  scrollToBottom() {
    var messagesDisplay = this.messagesDisplay.current;
    messagesDisplay.scrollTop = messagesDisplay.scrollHeight;
  }

  render() {
    const messages = [];
    for (const message of this.state.messages) {
      messages.push(
        <Message messageJson={message} onImgLoaded={(id) => {this.scrollToBottom()}} />);
    }

    const imagesToUpload = [];
    var i = 0;
    for (const uploadImageSrc of this.state.uploadImageSrcs) {
      const index = i;
      imagesToUpload.push(
        <Col md={3} style={{height: '100%'}}>
          <row>
            <img src={uploadImageSrc.src} style={{'object-fit': 'cover'}} width={'100%'} height={'90%'} />
          </row>
          <div style={{height: '10%', width: '100%', 'text-align': 'center', display: 'block'}}>
            <a href='' onClick={(event) => {this.removeFromImageUploads(index); event.preventDefault()}}>Delete</a>
          </div>
        </Col>
      );
      i++;
    }

    const uploadImageButtonVisibility = this.state.uploadImageSrcs.length >= 4 ? 'hidden' : 'visible';

    return (
      <div style={{height: '100%'}}>
        <NameInputModal eventId={this.state.eventId} show={this.state.showNameInputModal} onUsernameChosen={this.onUsernameChosen} />
        <br/>
        <br/>
        <h1>{this.state.eventData.name == null ? 'Event' : this.state.eventData.name}</h1>
        <br/>
        <div ref={this.messagesDisplay} style={{'overflow-y': 'auto', height: this.state.uploadImageSrcs.length === 0 ? '60vh' : '35vh', border: '1px solid'}}>
          {messages}
        </div>
        {this.state.uploadImageSrcs.length > 0 ? <br/> : ''}
        <Row style={{height: this.state.uploadImageSrcs.length === 0 ? '0vh' : '20vh'}}>
          {imagesToUpload}
        </Row>
        <br/>
        <div style={{height: '10vh'}}>
          <div className='row'>
            <div className="col-md-9 col-sm-7 justify-content-center align-self-center">
              <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Enter a message..."
                  ref="messageField"
                  onChange={this.onNewMessageChange}
                  onKeyDown={this.detectEnterSubmit} />
            </div>
            <div class="col-md-1 col-sm-2 text-center justify-content-center align-self-center">
              <label for="fileInput">
                <img src={'/image.png'} width='75%' height='75%' max-width='128px' max-height='128px' style={{cursor: 'pointer', 'padding-top': '10px', visibility: uploadImageButtonVisibility}} />
              </label>
              <input type="file" id="fileInput" accept=".png, .jpg, .jpeg, .gif" style={{display: 'none'}} disabled={this.state.uploadImageSrcs.length >= 4} />
            </div>
            <div className="col-md-2 col-sm-3 justify-content-center align-self-center">
              <Button
                  className="btn btn-block btn-lg btn-primary"
                  onClick={this.onSubmit}
                  disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')}>
                Send
              </Button>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

export default Event;
