import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import * as constants from './Constants.js';

class CreateEvent extends React.Component {
  constructor(props) {
    super(props);

    this.eventName = '';

    this.state = {
      isCurrentlySubmitting: false
    };

    this.onEventNameChange = this.onEventNameChange.bind(this);
    this.detectEnterSubmit = this.detectEnterSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onEventNameChange(event) {
    this.eventName = event.target.value;
  }

  detectEnterSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onSubmit();
    }
  }

  onSubmit() {
    console.log(this.eventName);
    this.setState({isCurrentlySubmitting: true});
    fetch(constants.API_ADDRESS + '/api/event', {
      method: 'POST',
      crossDomain: true,
      body: JSON.stringify({
        'name': this.eventName
      })
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      this.setState({isCurrentlySubmitting: false});
      window.location.href = '/event/' + response.event_id;
    }).catch(function(error) {
      console.log(error);
      // TODO: Display error to user.
    });
  }

  render() {
    return (
      <div>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <form>
            <div class="form-row">
              <div class="col-12 col-md-9 mb-2 mb-md-0">
                <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Enter your event name..."
                    onChange={this.onEventNameChange}
                    onKeyDown={this.detectEnterSubmit} />
              </div>
              <div class="col-12 col-md-3">
                <Button className="btn btn-block btn-lg btn-primary" onClick={this.onSubmit} disabled={this.state.isCurrentlySubmitting ? ('disabled') : ('')}>Go!</Button>
              </div>
            </div>
          </form>
        </Col>
      </Row>
      </div>
    );
  }
}

class Event extends React.Component {
  render () {
    const imageCol = this.props.imageUrl == null ? '' : (
      <Col style={{height: '200px'}} className='text-center' xs={12} md={12}>
        <Image style={{'object-fit': 'cover', width: '100%', height: '100%'}} src={this.props.imageUrl} />
      </Col>);
    return (
      <div style={{cursor: 'pointer'}} onClick={() => {console.log('wtf'); window.location.href = "/event/" + this.props.id}}>
      <Row><Col className='text-center'><b>{this.props.name}</b></Col></Row>
      <br/>
      <Row>
        {imageCol}
      </Row>
      </div>
    );
  }
}

class FeaturedEvents extends React.Component {
  render() {
    const rows = [];
    var cols = [];
    for (const evt of this.props.events) {
      cols.push(
        <Col md={4} className='text-center'>
          <Event
              id={evt['id']}
              name={evt['name']}
              imageUrl={evt['image_url']}
              />
        </Col>);
      if (cols.length === 3) {
        rows.push(
          <div><Row>{cols}</Row><br/><br/></div>
        );
        cols = [];
      }
    }
    if (cols.length !== 0) {
      rows.push(<Row>{cols}</Row>);
    }
    return (
      <div>
      {rows}
      </div>
    );
  }
}

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      featuredEvents: []
    }
  }

  componentDidMount() {
    this.loadFeaturedEvents((events) => {
      const featuredEvents = events.events == null ? [] : events.events;
      this.setState({featuredEvents: featuredEvents});
    });
  }

  loadFeaturedEvents(onRetrievedFeaturedEventsFn) {
    fetch(constants.API_ADDRESS + '/api/batch/featured-events', {
      method: 'GET',
      crossDomain: true,
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      onRetrievedFeaturedEventsFn(response);
    });
  }

  render() {
    return (
      <div>
      <br/><br/><br/>
      <Row>
        <Col md={12}>
          <h1 class='text-center'>Livestream chat, memes, and more!</h1>
        </Col>
      </Row>
      <br/>
      <CreateEvent />
      <br/><br/><br/>
      <Row>
        <Col md={4} className='text-center'>
          <Row><Col md={12}><img src={'./cursor.svg'} width={50} height={50} /></Col></Row>
          <br/>
          <Row><Col md={12}>Create or join a conversation.</Col></Row>
        </Col>
        <Col md={4} className='text-center'>
          <Row><Col md={12}><img src={'./handshake.svg'} width={50} height={50} /></Col></Row>
          <br/>
          <Row><Col md={12}>Share the event link with participants.</Col></Row>
        </Col>
        <Col md={4} className='text-center'>
          <Row><Col md={12}><img src={'./mic.svg'} width={50} height={50} /></Col></Row>
          <br/>
          <Row><Col md={12}>Chat on!</Col></Row>
        </Col>
      </Row>
      <br/><br/><br/><br/>
      <Row>
        <Col md={12}>
          <h2 class='text-center'>Featured Events</h2>
        </Col>
      </Row>
      <br/><br/>
      <FeaturedEvents events={this.state.featuredEvents} />
      <br/><br/><br/><br/><br/><br/>
      </div>
    );
  }
}

export default Home;
